<template>
    <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
    <div class="scroll"></div>
    <footer class="footer-bar">
        <div class="social-items">
            <a href="https://www.linkedin.com/company/simplic-gmbh/"><i class='bx bxl-linkedin'></i></a>
            <a href="https://m.facebook.com/profile.php?id=100055548893017&_rdr"><i class='bx bxl-facebook'></i></a>
            <a href="https://www.instagram.com/simplicgmbh/"><i class='bx bxl-instagram'></i></a>
            <a href="https://twitter.com/simplic_gmbh"><i class='bx bxl-twitter'></i></a>
            <a href="https://simplic.biz/">
                <svg viewBox="0 0 692 1237" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M481.968 545.028L272.52 754.477L481.968 963.925L691.417 754.477L481.968 545.028Z" fill="#44DD7D"></path>
                    <path d="M209.449 817.538L0 1026.99L209.449 1236.43L418.897 1026.99L209.449 817.538Z" fill="#44DD7D"></path>
                    <path d="M209.449 272.51L0 481.959L209.449 691.408L418.897 481.959L209.449 272.51Z" fill="#44DD7D"></path>
                    <path d="M481.968 0.000667708L272.52 209.449L481.968 418.898L691.417 209.449L481.968 0.000667708Z" fill="#44DD7D"></path>
                </svg>
            </a>
        </div>
    </footer>
</template>

<style>
.scroll{
    height: 150px;
}

.footer-bar {
    background-color: #16324f;
    height: 50px;
    width: 100%;
    position: fixed;
    bottom: 0;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-items {
    color: #ffffff;
}

.social-items a svg{
    width: 13px;
}

.social-items a i {
    color: #ffffff;
    margin-right: 12px;
    font-size: 25px
}

.social-items a i:hover {
    color: #44DD7D;
}

@media screen and (max-width: 600px){

    .social-items a i {
        font-size: 15px
    }

    .footer-bar {
        height: 40px;
    }


}

</style>