<template>
  <div class="body-opt">
    <NavBar />
    <div class="render-view">
      <router-view/>
    </div>
    <FooterBar /> 
  </div>
</template>

<script>
import NavBar from '@/views/NavBarDropDown.vue'
import FooterBar from '@/views/FooterBar.vue'

export default {
name: 'app',
components: {
  NavBar,
  FooterBar
},
created() {
  document.title = 'Simplic Bausteinfinder';
}
}


</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,900");
@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
* {
  
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  font-family: 'Aldrich', sans-serif; 
}

body {
    background-color: #ffffff;
    hyphens: auto;
}

::selection {
    color: #ffffff;
    background-color: #8ed1fc;
}

.msg {
    background-color: #44DD7D;
    border-radius: 10px;
    width: 300px;
    padding: 10px;
}

.msg p{
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}

.error-msg{
    background-color: #DE7371;
    border-radius: 10px;
    width: 300px;
    padding: 10px;
}

.error-msg p{
    font-family: 'Poppins', sans-serif;
    color: #ffffff;
}

.top-content {
    margin-top: 3%;
    margin-left: 18%;
}

.navbar-item a {
    font-family: 'Poppins', sans-serif;
    color: #535353;
    text-decoration: none;
}

.navbar-item a:hover {
    color: #8ed1fc;
}

.text-content {
    font-family: 'Poppins', sans-serif;
    color: rgba(0, 0, 0, 0.468);
}

.text-content p {
    font-family: 'Poppins', sans-serif;
    color: #16324f;
    text-transform: uppercase;
    font-weight: 100;
    font-size: 30px;
    font-weight: bold;
}

.top-content span {
    color: rgba(0, 0, 0, 0.468);
}

.modul-content {
    margin-top: 40px;
    margin-left: 18%;
    margin-right: 18%;
}

.choose-btn-class {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.choose-btn{
    height: 40px;
    width: 120px;
    padding: 4px;
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid #b4d2ff;
    border-radius: 0.6em;
    color: #b4d2ff;
    cursor: pointer;
    display: flex;
    align-items: center;
    line-height: 1;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
}

.choose-btn:hover {
    color: #ffffff;
    outline: 0;
    box-shadow: 0 0 40px 40px #b4d2ff inset;
}

.choose-btn p {
    width: 100%;
    display: inline-block;
    align-items: center;
    hyphens: auto;
}

.content-text {
    color: #16324f
}

.content-text p{
    font-family: 'Poppins', sans-serif;
}


</style>