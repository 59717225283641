import { createRouter, createWebHistory } from 'vue-router'

const routes = [
 
  {
    path: '/',
    name: 'index',
    component: () => import('../views/IndexView.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/MapView.vue')
  },
  {
    path: '/overview',
    name: 'overview',
    component: () => import('../views/OverviewView.vue')
  },
  {
    path: '/company-profile',
    name: 'company-profile',
    component: () => import('../views/CompanyProfile.vue')
  },
  {
    path: '/question',
    name: 'question',
    component: () => import('../views/QuestionView.vue')
  },
  {
    path: '/process',
    name: 'process',
    component: () => import('../views/ProcessView.vue')
  },
  {
    path: '/intermediate',
    name: 'intermediate',
    component: () => import('../views/IntermediatePage.vue')
  }
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (from.name === 'question' && to.name !== 'intermediate') {
    const confirmChanges = confirm('Möchten Sie die Seite verlassen, ohne die Änderungen zu speichern?');
    if (!confirmChanges) {
      next(false);
      return;
    }
  }
  next();
});

export default router