<template>
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
    
    <body>
        <nav class="navbar">
            <div class="nav-container">
                <div class="simplic-logo">
                    <router-link to="/">
                        <img src="https://simplic.biz/wp-content/themes/simplic/src/images/logo.png">
                    </router-link>
                    <div class="toggle-button">
                        <a class="button" id="toggle-button" @click="navbarFunc">
                            <span class="bar"></span><span class="bar"></span><span class="bar"></span>
                        </a>
                    </div>
                </div>
                <div class="navbar-links" id="navbar-links">
                    <ul>
                        <li><router-link to="/"><span>Start</span></router-link></li>
                        <li><router-link to="/company-profile">Profil</router-link></li>
                        <li><router-link to="/map">Map</router-link></li>
                        <li><router-link to="/process">Prozesse & Module</router-link></li>
                        <li><router-link to="/overview">Download</router-link></li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="scroll"></div>
    </body>
    
</template>

<script>
export default {
    methods: {
        navbarFunc() {
            var navbarLinks = document.getElementById('navbar-links');
            navbarLinks.classList.toggle('active');
        }
    }
}
</script>

<style>
* {
    box-sizing: border-box;
}

.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 105px;
    z-index: 1;
    background-color: #16324f;
}

.nav-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.navbar-links ul{
    display: flex;
}

.navbar-links li{
    list-style: none;
}

.navbar-links li a{
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    color: white;
    padding: 25px;
    display: block;
}

.navbar-links li a span {
    font-family: 'Poppins', sans-serif;
    color: #44DD7D;
    font-weight: bold;
}

.navbar-links li a:hover{
    color: #44DD7D;
}

.button{
    position: absolute;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    width: 40px;
    height: 22px;
}

.bar {
    height: 4px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
}

@media screen and (min-width: 1921px){
    .navbar {
        height: 5.46875vw;
    }
}

@media screen and (min-width: 1000px){   
    .nav-container {
        max-width: 83.02083vw;
    }

    .navbar-links li a{
        font-size: 1.3vw;
    
    }
}


@media screen and (max-width: 600px){

    .simplic-logo img{
        width: 140px;
        height: 35px;
    }

    .simplic-logo{
        margin-right: 25px;
    }

    .toggle-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .button{
        margin-left: 25px;
    }

    .bar {
        height: 3px;
        width: 100%;
        background-color: white;
        border-radius: 10px;
    }

    
}

@media screen and (max-width: 1000px){
    .simplic-logo{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px;
        width: 100%;
    }

    .toggle-button{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 20px;
        padding-bottom: 5px;
    }

    .button{
        display: flex;
    }

    .navbar{
        height: auto;
    }
    
    .navbar-links{
        width: 100%;
        display: none;
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-links li a:hover{
        background-color: white;
    }

    .nav-container{
        flex-direction: column;
        z-index: 1;
    }

    .navbar-links ul{
        background-color: #787D87;
        width: 100%;
        flex-direction: column;
    }

    .navbar-links li{
        height: auto;
        text-align: center;
    }

    .navbar-links li a{
        padding: .9rem;
    }

    .navbar-links.active {
        display: flex;
    }
}
</style>